import clevertap from "clevertap-web-sdk";
import { useContext, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// icons
import { SvgBooking } from "assets/icons/js/SvgBooking";
import { SVGContactUs } from "assets/icons/js/SVGContactUs";
import { SvgDownArrow as DownArrow } from "assets/icons/js/SvgDownArrow";
import { SvgHelpAndSupport } from "assets/icons/js/SvgHelpAndSupport";
import { SvgHomeIconDark } from "assets/icons/js/SvgHomeIconDark";
import { SvgHomeLogo as HomeLogo } from "assets/icons/js/SvgHomeLogo";
import { SvgLogout } from "assets/icons/js/SvgLogout";
import { SvgMenu as Menu } from "assets/icons/js/SvgMenu";
import { SvgNewHomeLogo as NewHomeLogo } from "assets/icons/js/SvgNewHomeLogo";
import { SvgProfile } from "assets/icons/js/SvgProfile";
import { SvgReward } from "assets/icons/js/SvgReward";
import { SvgSearch } from "assets/icons/js/SvgSearch";
import { SvgWishlist } from "assets/icons/js/SvgWishlist";
import { ReactComponent as PhoneSvg } from "assets/phone.svg";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";
import { useOnClickOutside } from "customHooks/useOnClickOutside";
import { useUser } from "customHooks/useUser";

// store
import { UserContext } from "store";

// scss
import "./style.scss";

export const Navbar = ({
	setShouldShowModal,
	setIsMenuOpen,
	isMenuOpen,
	link,
	isBackgroundTransparent = false,
}) => {
	const [userDropdown, setUserDropdown] = useState(false);
	const [, setActive] = useState(false);

	// getting current user data
	const { state } = useContext(UserContext);
	const { currentUser } = state;

	// ref
	const menuRef = useRef();
	const dropdownRef = useRef();

	// location
	const { pathname } = useLocation();

	// onClickOutside close popup
	useOnClickOutside(menuRef, () => setActive(false));
	useOnClickOutside(dropdownRef, () => setUserDropdown(false));

	// widh
	const width = useMediaQuery();

	// to check if user exist
	const user = useUser();

	// to logout the user
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("uuid");
		window.location.reload();
	};

	const getRedirectLink = () => {
		const token = localStorage.getItem("token");
		const isHomeowner = currentUser.isHomeowner;

		if (token && isHomeowner)
			return `https://host.saffronstays.com/account/dashboard?jwt=${token}`;
		return `https://www.partner.saffronstays.com`;
	};

	const { dispatch } = useContext(UserContext);
	return (
		<div
			className="navbar"
			style={{
				borderBottom: !isBackgroundTransparent && "1px #dddddd solid",
				position: !isBackgroundTransparent && "relative",
				background: !isBackgroundTransparent && "#ffffff",
			}}
		>
			<div className="navbar-wrapper">
				<div className="navbar-logo">
					<Link aria-label="logo" to="/">
						<span className="logo">
							{isBackgroundTransparent ? (
								<HomeLogo height={width <= "320" ? 36 : 50} />
							) : (
								<NewHomeLogo height={width <= "320" ? 36 : 50} />
							)}
						</span>
					</Link>
				</div>

				{(pathname.split("/")[1] === "view" ||
					pathname.split("/")[1] === "listing") && (
					<div
						className="navbar__destination-search"
						onClick={() => {
							clevertap.event.push("navbarsearch_input_listing_page", {
								location: pathname.split("/")[2],
								timestamp: new Date(),
							});
							window.location.href = link;
							return;
						}}
					>
						<p>Search for a destination or a home</p>
						<SvgSearch color="#ddd" />
					</div>
				)}
				{width >= "1250" ? (
					<>
						<div className="navbar__links-box">
							{/* <div
								className="navbar-link icons"
								style={{
									borderBottom: active && "5px #2368a2 solid",
								}}
								ref={menuRef}
							>
								<span
									className="nav-link"
									style={{
										color: !isBackgroundTransparent && "#333333",
									}}
									onClick={() => setActive(!active)}
								>
									<p
										style={{
											color: !isBackgroundTransparent && "#333333",
										}}
									>
										Explore Ideas
									</p>
									<DownArrow
										height={12}
										color={!isBackgroundTransparent ? "#333333" : "#ffffff"}
									/>
								</span>
								<span className="arrow-icon"></span>
								{active && <NavbarExplore />}
							</div> */}
							<div className="navbar-link icons">
								<a
									aria-label="Phone Number"
									className="nav-link"
									href={"tel:08069160000"}
									style={{
										color: !isBackgroundTransparent && "#333333",
										gap: "10px",
									}}
								>
									<PhoneSvg />
									08069160000
								</a>
							</div>
							<div className="navbar-link icons">
								<a
									aria-label="HomeOwner"
									className="nav-link"
									href={getRedirectLink()}
									style={{
										color: !isBackgroundTransparent && "#333333",
									}}
								>
									For Home Owners
								</a>
							</div>
							{!user && (
								<button
									aria-label="Login"
									className={`navbar-link-button ${
										!isBackgroundTransparent && "changeButton"
									}`}
									onClick={() => {
										dispatch({
											type: "FROM_BOOK_BUTTON",
											payload: false,
										});
										setShouldShowModal(true);
									}}
									style={{
										marginRight: "1.2rem",
									}}
									title="Log in / Sign up"
								>
									Log in / Sign up
								</button>
							)}
							{user && (
								<div className="navbar-profile-dropdown" ref={dropdownRef}>
									<div
										className="profile__user-detail"
										onClick={() => setUserDropdown(!userDropdown)}
										style={{
											borderBottom: userDropdown ? " 5px #2368a2 solid" : "",
										}}
									>
										<span className="user-img">
											<img
												src={`https://api.dicebear.com/7.x/initials/svg?seed=${currentUser.firstName}`}
												alt="#"
											/>
										</span>
										<span className="user-detail">
											<h5
												style={{
													color: !isBackgroundTransparent && "#333333",
												}}
											>
												{currentUser.firstName}
											</h5>
											<p
												style={{
													color: !isBackgroundTransparent && "#333333",
												}}
											>
												{currentUser.rewardPoint} points
											</p>
										</span>
										<DownArrow
											height={12}
											color={!isBackgroundTransparent ? "#333333" : "#ffffff"}
										/>
									</div>
									{userDropdown && (
										<div className="user__dropdown-box">
											<Link
												aria-label="profile"
												to="/dashboard"
												className="dropdown-link"
											>
												<SvgProfile />
												<p>Profile</p>
											</Link>
											<Link
												aria-label="bookings"
												to="/my-booking"
												className="dropdown-link"
											>
												<SvgBooking />
												<p>My Bookings</p>
											</Link>
											{currentUser.isHomeowner && (
												<a
													aria-label="MyHome"
													href={`https://host.saffronstays.com/account/dashboard?jwt=${localStorage.getItem(
														"token",
													)}`}
													className="dropdown-link"
													target="_blank"
													rel="noreferrer"
												>
													<SvgHomeIconDark />
													<p>My homes</p>
												</a>
											)}
											<Link
												aria-label="rewards"
												to="/dashboard"
												className="dropdown-link"
											>
												<SvgReward />
												<p>Rewards</p>
											</Link>
											<Link
												aria-label="wishlist"
												to="/collection"
												className="dropdown-link"
											>
												<SvgWishlist className="svg-wishlist" />
												<p>Wishlist</p>
											</Link>
											<Link
												aria-label="contact-us"
												to="/contact-us"
												className="dropdown-link"
											>
												{/* <SvgWishlist className="svg-wishlist" /> */}
												<SVGContactUs />
												<p>Contact Us</p>
											</Link>
											<Link
												aria-label="help-support"
												to="/help-and-policies"
												className="dropdown-link"
											>
												<SvgHelpAndSupport />
												<p>Help & Support</p>
											</Link>
											<hr />
											<button
												aria-label="Logout"
												type="button"
												className="btn-logout"
												onClick={handleLogout}
											>
												<SvgLogout className="svg-logout" />
												<p> Log out</p>
											</button>
										</div>
									)}
								</div>
							)}
						</div>
					</>
				) : (
					<div
						className="menu-icon"
						style={{ marginRight: "20px", cursor: "pointer" }}
						onClick={() => setIsMenuOpen(true)}
					>
						<Menu color={!isBackgroundTransparent ? "black" : ""} />
					</div>
				)}
			</div>
		</div>
	);
};
