import axios from "axios";
import { Fragment, Suspense, lazy, useContext, useEffect } from "react";
import {
	BrowserRouter as Router,
	Navigate,
	Routes,
	Route,
	useNavigate,
	useParams,
} from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer } from "react-toastify";
//context
import { IndicatorContext, IndicatorProvider } from "context/IndicatorProvider";
import { SearchBarData } from "context/SearchBarData";
import { SearchPageData } from "context/SearchPageData";
import { UserContext } from "store";

// helpers
import getUserData from "helpers/getUserDataAfterLogin";
import MD5 from "helpers/MD5HashGenerator";

//scss
import "./App.scss";

//pages
// import { GiftingCard } from "components/GiftingCard";
import { RegisterFormData } from "context/RegisterForm";
import { RewardPointsAndCoupons } from "components/RewardPointsAndCoupons";
import Modal from "components/LoginAndRegister/Modal";
import { GuestGiftCardTerms } from "components/PolicyCenter/PolicyPageContent/GuestGiftCardTerms";
import { NewFormPage } from "pages/NewFormPage";
import ExistingBooking from "components/NewForm/NewFormFlows/ExistingBooking";
import BookingPage from "pages/BookingPage";
import RenderHTML from "components/RenderHTML";
import UnsubscribeComponent from "pages/Unsubscribe";
import { elasticLog } from "helpers/elastic";
import { ListingTheme } from "components/ListingNew/ListingTheme";
import ClaimFriendCoupon from "components/UniversalUrlNew/LeftSide/ReviewHeader/NPS/ClaimFriendCoupons";
import ClaimFriendCouponPage from "pages/ClaimFriendCouponPage";
import MarketingPage from "pages/MarketingPage";

const TAPage = lazy(() => import("pages/TAPage"));

// import { AddOnDataProvider } from "context/AddonData";
const PhotosSearch = lazy(() => import("components/Photos"));

const GiftingCardPage = lazy(() => import("pages/GiftingCardPage"));
const Payout = lazy(
	() => import("components/Dashboard/MonitorDashboard/Payout"),
);
const ForgetPasswordPage = lazy(() => import("pages/ForgetPasswordPage"));
const GiftingCreatePage = lazy(() => import("pages/GiftingCreatePage"));
const GiftingPage = lazy(() => import("pages/GiftingPage"));

const AffliateFormPage = lazy(() => import("pages/AffliateFormPage"));
const AffliatePage = lazy(() => import("pages/AffliatePage"));
const OrdersPage = lazy(() => import("pages/OrdersPage"));

const JoinUsPage = lazy(() => import("pages/JoinUsPage"));
const AskSaffronStays = lazy(
	() => import("pages/HelpAndPolicies/ContactUs/AskSaffronStays"),
);
const HelpAndPoliciesPage = lazy(
	() => import("pages/HelpAndPolicies/HelpAndPoliciesPage"),
);
const PolicyCenterPage = lazy(() => import("pages/PolicyCenterPage"));
const PolicyPage = lazy(() => import("pages/PolicyPage"));
const FormDashboard = lazy(() => import("pages/registerForm"));
const ReportBug = lazy(() => import("pages/ReportBug.tsx"));
const ShareMemoryPage = lazy(() => import("pages/ShareMemoryPage"));

const TravelPartnerPage = lazy(() => import("pages/TravelPartnerPage"));

const HomePage = lazy(() => import("pages/HomePage"));
const SearchPage = lazy(() => import("pages/SearchPageNew"));
const ListingPage = lazy(() => import("pages/ListingPage"));
const MobileSearchPage = lazy(() => import("pages/MobileSearch"));
const OldBookingPage = lazy(() => import("pages/OldBookingPage"));
const CollectionPage = lazy(() => import("pages/CollectionPage"));
const DashboardPage = lazy(() => import("pages/DashboardPage"));
const UniversalURLPage = lazy(() => import("pages/UniversalURLPage"));
const MyBookingPage = lazy(() => import("pages/MyBookingPage"));
const DetailsViewPage = lazy(() => import("pages/DetailsViewPage"));
const AboutUsPage = lazy(() => import("pages/AboutUsPage"));
const ContactUsPage = lazy(() => import("pages/ContactUsPage"));
const PartnerShip = lazy(() => import("pages/Partnership"));
const MealPreferencePage = lazy(() => import("pages/MealPreferencePage"));
const ClubInfinitumPage = lazy(() => import("pages/ClubInfinitumPage"));
const PageNotFound = lazy(() => import("pages/PageNotFound"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const SiteMapPage = lazy(() => import("pages/Sitemap"));
const CollectionDetailsPage = lazy(() => import("pages/CollectionDetailsPage"));
const RedirectPage = lazy(() => import("pages/RedirectPage"));
const OffersPage = lazy(() => import("pages/OffersPage"));
const TicketChatPage = lazy(() => import("pages/TicketChatPage"));
const AprilFoolsPage = lazy(() => import("pages/AprilFoolsPage"));
const GuestCheckinPage = lazy(() => import("pages/GuestCheckinPage"));
const ReviewPage = lazy(() => import("pages/ReviewPage"));
interface IProps {
	children: JSX.Element;
	auth: boolean;
}

const Protected = ({ auth, children }: IProps) => {
	const queryString = new URLSearchParams(window.location.search);
	const urlJwt = queryString.get("jwt");

	if (!auth && urlJwt) {
		localStorage.setItem("token", urlJwt);
		localStorage.setItem("allow", "true");
		return children;
	}

	return auth ? children : <Navigate to="/login" />;
};

const UnProtected = ({ auth, children }: IProps) => {
	const navigate = useNavigate();
	const queryString = new URLSearchParams(window.location.search);
	const urlJwt = queryString.get("jwt");

	if (!auth && urlJwt) {
		localStorage.setItem("token", urlJwt);
		localStorage.setItem("allow", "true");
		return children;
	}

	return children;
};

const Indicator = (): JSX.Element | null => {
	// @ts-ignore
	const { active } = useContext(IndicatorContext);

	return active ? <TopBarProgress /> : null;
};

function IndicatorFallback() {
	// @ts-ignore
	const { setActive } = useContext(IndicatorContext);

	useEffect(() => {
		setActive(true);
		return () => setActive(false);
	});
	return null;
}

const RandomPageRedirector = () => {
	const links = [
		"https://forms.gle/VyXihTUqsgWe6hj19",
		"https://forms.gle/V1UTabVkhDbGZzjW7",
		"https://forms.gle/3r1dyKp558ZpXZRo8",
	];
	const randomIndex = Math.floor(Math.random() * links.length);
	const selectedLink = links[randomIndex];
	// console.log(selectedLink);

	return selectedLink;
};

export default function App() {
	const isAuthenticated = localStorage.getItem("token") ? true : false;
	// const [showRewardPopup, setShowRewardPopup] = useState(false);

	const { dispatch } = useContext(UserContext);

	useEffect(() => {
		getUserData(dispatch);
		// eslint-disable-next-line
	}, [window.location.pathname]);

	// useEffect(() => {
	// 	if (document?.referrer)
	// 		elasticLog({
	// 			index: "source-traffic",
	// 			payload: {
	// 				referrer: document.referrer,
	// 				page: window.location.href,
	// 			},
	// 		});
	// }, []);

	useEffect(() => {
		const queryString = new URLSearchParams(window.location.search);
		// @ts-ignore
		let data = JSON.parse(localStorage.getItem("TRAFFIC_SOURCE"));
		// @ts-ignore
		let updatedReferrer = JSON.parse(localStorage.getItem("UPDATED_REFERRER"));
		// @ts-ignore
		let initialReferrer = JSON.parse(localStorage.getItem("INITIAL_REFERRER"));

		let src = document.referrer;

		if (src) {
			localStorage.setItem(
				"INITIAL_REFERRER",
				JSON.stringify({ from: src, to: window.location.href }),
			);
			localStorage.setItem(
				"UPDATED_REFERRER",
				JSON.stringify([src, window.location.href]),
			);
		} else {
			if (updatedReferrer?.length > 0) {
				localStorage.setItem(
					"UPDATED_REFERRER",
					JSON.stringify([...updatedReferrer, window.location.href]),
				);
			}
		}

		if (!data && queryString.get("TRAFFIC_SOURCE") !== null) {
			localStorage.setItem(
				"TRAFFIC_SOURCE",
				JSON.stringify([queryString.get("TRAFFIC_SOURCE")]),
			);
		}
		if (
			data &&
			queryString.get("TRAFFIC_SOURCE") !== null &&
			!data.includes(queryString.get("TRAFFIC_SOURCE"))
		) {
			let prevData = [...data, queryString.get("TRAFFIC_SOURCE")];
			localStorage.setItem("TRAFFIC_SOURCE", JSON.stringify(prevData));
		}

		if (queryString.get("utm_source")) {
			localStorage.setItem(
				"utm_source",
				// @ts-ignore
				queryString.get("utm_source"),
			);
		}
		if (queryString.get("utm_medium")) {
			// @ts-ignore
			localStorage.setItem("utm_medium", queryString.get("utm_medium"));
		}

		if (queryString.get("utm_campaign")) {
			// @ts-ignore
			localStorage.setItem("utm_campaign", queryString.get("utm_campaign"));
		}
		if (queryString.get("utm_term")) {
			// @ts-ignore
			localStorage.setItem("utm_term", queryString.get("utm_term"));
		}
		if (queryString.get("utm_content")) {
			// @ts-ignore
			localStorage.setItem("utm_content", queryString.get("utm_content"));
		}
	}, []);

	async function getIP() {
		try {
			// @ts-ignore
			const { data } = await axios.get(
				"https://ecapi.saffronstays.com/get/user/ip-addr",
			);

			if (data?.data?.isBlacklist) {
				document.body.innerHTML = "";
			}

			// @ts-ignore
			const hashIP =
				MD5(data.data.data.IP).toString() + Math.random().toString();

			if (!localStorage.getItem("guestUniqueId")) {
				localStorage.setItem("guestUniqueId", hashIP);
			}
			localStorage.setItem(
				"location",
				JSON.stringify({
					lat: data.data.lat,
					lon: data.data.lon,
					city: data.data.city,
				}),
			);
		} catch (err) {
			console.warn("error" + err);
		}
	}

	useEffect(() => {
		if (!localStorage.getItem("guestUniqueId")) {
			getIP();
		}
		getIP();
	}, []);

	// useEffect(() => {
	// 	if (
	// 		localStorage.getItem("token") &&
	// 		localStorage.getItem("showReward") === null
	// 	) {
	// 		setShowRewardPopup(true);
	// 	}
	// }, []);

	// const handleClose = () => {
	// 	setShowRewardPopup(false);
	// 	localStorage.setItem("showReward", "false");
	// };

	// useEffect(() => {
	// 	window.addEventListener("click", function (event: any) {
	// 		var target = event.target || event.srcElement;

	// 		if (
	// 			target &&
	// 			target?.tagName &&
	// 			target?.tagName?.toLowerCase() === "img"
	// 		) {
	// 			elasticLog({
	// 				index: "image-click-log",
	// 				payload: {
	// 					img: target?.src,
	// 					page: this.window.location.href,
	// 				},
	// 			});
	// 		}
	// 	});
	// }, []);

	return (
		<div className="App">
			<IndicatorProvider>
				<Indicator />
				<ToastContainer position="bottom-right" closeOnClick autoClose={2000} />
				{/* <Modal shouldShow={showRewardPopup} onRequestClose={handleClose}>
					<RewardPointsAndCoupons onRequestClose={handleClose} />
				</Modal> */}
				<Router>
					<Fragment>
						<Routes>
							<Route
								path="/"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<SearchBarData>
											<HomePage />
										</SearchBarData>
									</Suspense>
								}
							/>
							<Route
								path="/s/:id"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<RedirectPage />
									</Suspense>
								}
							/>
							<Route
								path="/view/:id"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<ListingTheme>
											<ListingPage />
										</ListingTheme>
									</Suspense>
								}
							/>
							<Route
								path="/mobile-search/*"
								element={
									<SearchBarData>
										<Suspense fallback={<IndicatorFallback />}>
											<MobileSearchPage />
										</Suspense>
									</SearchBarData>
								}
							/>
							<Route
								path="/coupons-home"
								element={
									<SearchPageData>
										<SearchBarData>
											<Suspense fallback={<IndicatorFallback />}>
												<SearchPage />
											</Suspense>
										</SearchBarData>
									</SearchPageData>
								}
							/>
							{/* <Route
								path="/an-ideal-weekend-getaway/:id"
								element={
									<SearchPageData>
										<SearchBarData>
											<Suspense fallback={<IndicatorFallback />}>
												<SearchPage />
											</Suspense>
										</SearchBarData>
									</SearchPageData>
								}
							/> */}
							<Route
								path="/an-ideal-weekend-getaway/:id"
								element={
									<Redirect
										to={`/villas/villa-in-${window.location.pathname
											.split("/")
											.reverse()[0]
											?.replaceAll("%2C", ",")
											?.split(",")[0]
											?.trim()}`}
									/>
								}
							/>
							<Route
								path="/villas/*"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<SearchPageData>
											<SearchBarData>
												<SearchPage />
											</SearchBarData>
										</SearchPageData>
									</Suspense>
								}
							/>
							<Route
								path="/homestays/*"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<SearchPageData isVillas={"homestays"}>
											<SearchBarData isVillas={"homestays"}>
												<SearchPage isVillas={"homestays"} />
											</SearchBarData>
										</SearchPageData>
									</Suspense>
								}
							/>
							<Route
								path="/travel-agents"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<TAPage />
									</Suspense>
								}
							/>

							<Route
								path="/login"
								element={
									// @ts-ignore
									// <UnProtected auth={isAuthenticated}>
									<Suspense fallback={<IndicatorFallback />}>
										<LoginPage />
									</Suspense>
									// </UnProtected>
								}
							/>

							<Route path="/forget-password" element={<ForgetPasswordPage />} />
							<Route
								path="/listing/:id"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<ListingPage />
									</Suspense>
								}
							/>
							<Route
								path="/search"
								element={
									<SearchPageData>
										<SearchBarData>
											<Suspense fallback={<IndicatorFallback />}>
												<SearchPage />
											</Suspense>
										</SearchBarData>
									</SearchPageData>
								}
							/>
							<Route
								path="/collection"
								element={
									<Protected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<CollectionPage />
										</Suspense>
									</Protected>
								}
							/>
							{/* <Route
								path="/booking/:id"
								element={
									<Protected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<OldBookingPage />
										</Suspense>
									</Protected>
								}
							/> */}
							<Route
								path="/old-booking/*"
								element={
									<Protected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<OldBookingPage />
										</Suspense>
									</Protected>
								}
							/>
							<Route
								path="/meal"
								element={
									<Protected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<MealPreferencePage />
										</Suspense>
									</Protected>
								}
							/>
							<Route
								path="/collection/:id"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<CollectionDetailsPage />
									</Suspense>
								}
							/>
							<Route
								path="/dashboard"
								element={
									<Protected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<DashboardPage />
										</Suspense>
									</Protected>
								}
							/>
							<Route
								path="/payout"
								element={
									<Protected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<Payout />
										</Suspense>
									</Protected>
								}
							/>
							<Route
								path="/universalurl/:bookingID"
								element={
									// @ts-ignore
									<UnProtected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<UniversalURLPage />
										</Suspense>
									</UnProtected>
								}
							/>
							<Route
								path="/guestcheckin/:bookingID"
								element={
									// @ts-ignore
									<UnProtected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<GuestCheckinPage />
										</Suspense>
									</UnProtected>
								}
							/>
							<Route
								path="/claim/:bookingID"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<ClaimFriendCouponPage />
									</Suspense>
								}
							/>
							<Route
								path="/my-booking"
								element={
									<Protected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<MyBookingPage />
										</Suspense>
									</Protected>
								}
							/>
							<Route path="/join-us" element={<JoinUsPage />} />
							<Route
								path="/travelpartner"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<TravelPartnerPage />
									</Suspense>
								}
							/>

							<Route
								path="/details-view"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<DetailsViewPage />
									</Suspense>
								}
							/>
							{/* Guest Help pages start */}
							<Route
								path="/responsible-guest-help"
								element={<PolicyPage pageEndpoint="responsible-guest-help" />}
							/>
							<Route
								path="/guest-faq"
								element={<PolicyPage pageEndpoint="guest-faq" />}
							/>
							<Route
								path="/guest-refund-policy"
								element={<PolicyPage pageEndpoint="guest-refund-policy" />}
							/>
							<Route
								path="/gift-card-terms"
								element={<PolicyPage pageEndpoint="gift-card-terms" />}
							/>
							<Route
								path="/host-service-assurance"
								element={<PolicyPage pageEndpoint="host-service-assurance" />}
							/>
							{/* Guest Help pages end */}
							{/* Host Help pages start */}
							<Route
								path="/why-host"
								element={<PolicyPage pageEndpoint="why-host" />}
							/>
							<Route
								path="/host-tips-tricks"
								element={<PolicyPage pageEndpoint="host-tips-tricks" />}
							/>
							<Route
								path="/saffronstays-faq"
								element={<PolicyPage pageEndpoint="saffronstays-faq" />}
							/>
							<Route
								path="/help-me-list"
								element={<PolicyPage pageEndpoint="help-me-list" />}
							/>
							<Route path="/share-memory" element={<ShareMemoryPage />} />
							{/* Host Help pages end */}
							{/* Policy Center pages start */}
							<Route
								path="/help-and-policies"
								element={<HelpAndPoliciesPage />}
							/>
							<Route path="/policy-center" element={<PolicyCenterPage />} />
							<Route
								path="/terms-service"
								element={<PolicyPage pageEndpoint={"terms-service"} />}
							/>
							<Route
								path="/privacy-policy"
								element={<PolicyPage pageEndpoint={"privacy-policy"} />}
							/>
							<Route
								path="/cancellation-policy"
								element={<PolicyPage pageEndpoint={"cancellation-policy"} />}
							/>
							<Route
								path="/reschedule-policy"
								element={<PolicyPage pageEndpoint={"reschedule-policy"} />}
							/>
							<Route
								path="/trust-and-safety"
								element={<PolicyPage pageEndpoint={"trust-and-safety"} />}
							/>
							<Route
								path="/ready-for-covid19"
								element={<PolicyPage pageEndpoint={"ready-for-covid19"} />}
							/>
							<Route
								path="/extenuating-circumstances"
								element={
									<PolicyPage pageEndpoint={"extenuating-circumstances"} />
								}
							/>
							{/* Policy Center pages end */}
							<Route
								path="/help-ask-saffronstays"
								element={<AskSaffronStays />}
							/>
							<Route path="/join-us" element={<JoinUsPage />} />
							<Route
								path="/about-us"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<AboutUsPage />
									</Suspense>
								}
							/>
							<Route
								path="/form"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<ContactUsPage />
									</Suspense>
								}
							/>
							<Route
								path="/askme"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<ContactUsPage />
									</Suspense>
								}
							/>
							<Route
								path="/partnership"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<PartnerShip />
									</Suspense>
								}
							/>
							<Route
								path="/reportbugs"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<ReportBug />
									</Suspense>
								}
							/>
							<Route
								path="/club-infinitum"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<ClubInfinitumPage />
									</Suspense>
								}
							/>
							<Route
								path="/gifting"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<GiftingPage />
									</Suspense>
								}
							/>
							<Route
								path="/sitemap"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<SiteMapPage />
									</Suspense>
								}
							/>
							<Route path="*" element={<PageNotFound />} />
							<Route
								element={<ExistingBooking />}
								path="/contact-us/i-have-a-query-regarding-my-current/past-booking"
							/>
							<Route
								path="/contact-us/*"
								element={
									<RegisterFormData>
										<NewFormPage />
									</RegisterFormData>
								}
							/>

							<Route path="/create-gift" element={<GiftingCreatePage />} />
							<Route
								path="/order"
								element={
									<Protected auth={isAuthenticated}>
										<OrdersPage />
									</Protected>
								}
							/>
							<Route
								path="/offers"
								element={
									<Suspense fallback={<IndicatorFallback />}>
										<OffersPage />
									</Suspense>
								}
							/>
							<Route
								path="/booking/:id"
								element={
									<Protected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<BookingPage />
										</Suspense>
									</Protected>
								}
							/>
							<Route
								path="/test-booking/:id"
								element={
									<Protected auth={isAuthenticated}>
										<Suspense fallback={<IndicatorFallback />}>
											<AddBorder>
												<BookingPage />
											</AddBorder>
										</Suspense>
									</Protected>
								}
							/>
							<Route path="/gift-card-detail" element={<GiftingCardPage />} />
							<Route path="/affliate" element={<AffliatePage />} />
							<Route path="/affliate-form" element={<AffliateFormPage />} />
							<Route path="/apti-test" element={<RandomPage />} />
							<Route path="/feature" element={<MarketingPage />} />
							<Route
								path="/customer-chat/:id"
								element={
									<RediectNewWebsite
										to={"https://chat.saffronstays.com/chat/"}
									/>
								}
							/>
							<Route path="/water-villas" element={<AprilFoolsPage />} />
							<Route
								path="/unsubscribe"
								element={<UnsubscribeComponent />}
							></Route>
							<Route path="/discovery" element={<PhotosSearch />} />
							<Route path="/review/:id" element={<ReviewPage />} />
						</Routes>
					</Fragment>
				</Router>
			</IndicatorProvider>
		</div>
	);
}

// @ts-ignore
const Redirect = ({ to }) => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate(to);
	}, []);
	return null;
};

const RediectNewWebsite = ({ to }: { to: string }) => {
	const { id } = useParams();

	useEffect(() => {
		window.location.href = to + id;
	}, []);

	return null;
};

const RandomPage = () => {
	const link = RandomPageRedirector();
	window.location.href = link;
	return <>Loading...</>;
};

const AddBorder = ({ children }: any) => {
	useEffect(() => {
		// Get all elements on the page
		setTimeout(() => {
			const allElements = document.querySelectorAll("*");

			// Loop through each element and add the border
			allElements.forEach((element: any) => {
				element.style.border = "1px solid red";
			});

			// Clean up the styles when the component is unmounted (optional)
			return () => {
				allElements.forEach((element: any) => {
					element.style.border = "";
				});
			};
		}, 2000);
	}, []); // Emp
	return children;
};
