import { customDates } from "multi-date";

const restrictDates = ["2024-06-30"];

const restrictDates2 = ["2024-06-30"];

const restrictDatesGoa = [];

const GoaIds = [
	"1FZWk66fCBCN7mJa",
	"76vPjFXotKB21SZk",
	"8UV7fq6DBUgAjYfS",
	"bOeqYGR14FMMS4zE",
	"BQZ6ZEIO6UNbWXyZ",
	"Dx1FqYbzOWptyOGz",
	"ldHlFEx6lGaotjt2",
	"mLX860Fn9RxSQQWL",
	"nV3g7rO78vy5MaRn",
	"o2EGR6cIA1UH9cFX",
	"shgM3qyY7ZV6JvmM",
	"SsqrusersucGRHvf",
	"STET7IKX6BNzFMhg",
	"sZtgQtVa6CEeQdX2",
	"Uqdj8lF1BN6M8eKc",
	"vXkVEmP1cIKqxYzN",
	"VYhB37ZS1WxeHyon",
	"WD1ojL072QBXeZxj",
	"xk2qe7Rkr5duExn1",
	"XXphbQVGpf9CKQDn",
	"Yo5xMN6WA0bcyBrU",
	"zPicNIkypnwqPGCg",
];

var Ids = [
	"8UV7fq6DBUgAjYfS",
	"BQZ6ZEIO6UNbWXyZ",
	"ldHlFEx6lGaotjt2",
	"mLX860Fn9RxSQQWL",
	"p5RfsG6ie1uxecQ6",
	"STET7IKX6BNzFMhg",
	"sZtgQtVa6CEeQdX2",
	"Uqdj8lF1BN6M8eKc",
	"vXkVEmP1cIKqxYzN",
	"xk2qe7Rkr5duExn1",
];

const teamD = ["joi8Qfe1UVqOL97o", "fqFEAzcQboAIlGh8", "tjR09kJL8RRW5REW"];

const newYearExceptionIds = [
	"yCXzkDWCMLayPwzT",
	"y9W2ElDhRIjwXx0q",
	"xIpyS1j3tbdmjqY0",
	"xAYS1ubkZ7h3jzqp",
	"weZ52FsofV1lepoJ",
	"V5wWlgl0FPtsBJ3a",
	"tfFvJhyjzP70v2VN",
	"T4mkhkzQU2tjMK8t",
	"sBRo93HQajQjZ8Vi",
	"s5BTeCcQcvr6thld",
	"RLWyzzrvNfipxr7Z",
	"PYAbqzAOx3MEj5kw",
	"p1pOS3iEYOie5qlY",
	"OVCIQ1sDGs6KbaFI",
	"o34xRS7aTeLOzOZh",
	"mWUTf7WYsyjUDIHG",
	"me5X43BX7PxI6ki1",
	"lU8CMLfiaElR57YM",
	"l877824pN7YiUcEW",
	"kCrzh1wckgyrd32M",
	"Jab2PQYpzfulB3OF",
	"I0nGUx3DBr4u3fwh",
	"HZ9zsWMjnm638hsg",
	"hvLT1D4Z1r7wgVlN",
	"hm1wk9LdTKHybDQW",
	"h7RoH9qCXJcL88xj",
	"G5IlSii111A2CEQp",
	"G3p2PwiOfVn4rSw5",
	"ehBWwDW3Qq7Q5s8E",
	"eEF5Yv3z9bZibqg0",
	"EaesfP3scsz71ZDW",
	"dro2tG0kbeWIs3cB",
	"dFxcVUF0eQuZlHsk",
	"CT1zjKF6NNa4zLV6",
	"c3DJwGY1eqD6rXdC",
	"Bw3ugCTCKFKqS4fS",
	"bPI49vw4zVQLvcxC",
	"bgFUFCOfZQ7Aa4L7",
	"AqTjR3iGgObtDsry",
	"Ao19CVZbEw4fscJo",
	"9DhO4ioNv2fF2gNf",
	"7Sca2XOtRYf3zo24",
	"61r6A8wDRad6n4Zv",
	"4WxCe8El8D7EztyB",
	"4n25AV4ZEBzoXgqj",
	"2pj9qNukEKhq0AyR",
	"2N2Dkenx6PHlvwix",
	"1WGKCmSJGw6ZsBuX",
	"1d3IMB7nEBTKtApc",
];

const xseries = [
	"UE0wrcSakfquemWD",
	"rwAQfwRql9mh6Pzz",
	"REv8Uv4xZahexumu",
	"P2K0Aegh1KqdaQRD",
	"nV3g7rO78vy5MaRn",
	"mCblAqfC1yHemTqy",
	"JYqsxPHoA8HA5RKB",
	"HlZotox7E3nhO1fN",
	"FufadJjriDVXSmKG",
	"CcVpHcMYd4WG1AsR",
	"BQZ6ZEIO6UNbWXyZ",
];

export const minNightsData = (villa_id = "") => {
	const startDate = new Date();
	const endDate = customDates(new Date(), 6, "month");

	if (villa_id === "qsclv7nA4zMceSet") {
		return [];
	}
	if (teamD.includes(villa_id)) {
		return [...restrictDates];
	}

	if (xseries.includes(villa_id) || newYearExceptionIds.includes(villa_id)) {
		return [...restrictDates];
	}
	if (Ids.includes(villa_id)) {
		return [...restrictDates2];
	}
	if (GoaIds.includes(villa_id)) {
		return [...restrictDatesGoa];
	}
	if (villa_id === "I14eRBfO2Cz5tjPg") {
		return [];
	}
	if (villa_id === "sZtgQtVa6CEeQdX2") {
		return [...restrictDates2, ...OctToJan()];
	}
	if (villa_id === "c5OinTRYX2e0nUWZ") {
		return [...restrictDates2, ...getDatesInRange(startDate, endDate)];
	}
	return [...restrictDates2];
};

export default minNightsData;

function getDatesInRange(startDate, endDate) {
	const dates = [];
	const currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");
		const formattedDate = `${year}-${month}-${day}`;
		dates.push(formattedDate);

		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dates;
}

function OctToJan() {
	const startDate = new Date("2023-10-01");
	const endDate = new Date("2024-01-31");
	const dateList = [];

	const currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		const formattedDate = currentDate.toISOString().split("T")[0];
		dateList.push(formattedDate);
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dateList;
}

const getWeekendDates = () => {
	const startDate = new Date("2023-11-01"); // Start date for November 2023
	const endDate = new Date("2024-01-31"); // End date for January 2024
	const dates = [];

	// Loop through each date within the range
	while (startDate <= endDate) {
		// Check if the current date is a Saturday or Sunday
		if (startDate.getDay() === 0 || startDate.getDay() === 6) {
			const year = startDate.getFullYear();
			const month = String(startDate.getMonth() + 1).padStart(2, "0");
			const day = String(startDate.getDate()).padStart(2, "0");
			const formattedDate = `${year}-${month}-${day}`;
			dates.push(formattedDate);
		}

		// Move to the next day
		startDate.setDate(startDate.getDate() + 1);
	}

	return dates;
};
