import axios from "axios";

//@ts-ignore
const utmMedium: string =
	localStorage.getItem("utm_medium") !== null
		? localStorage.getItem("utm_medium")
		: "";

// @ts-ignore
const utmSource: string = localStorage.getItem("utm_source")
	? localStorage.getItem("utm_source")
	: "";

//@ts-ignore
const uniqueId: string =
	localStorage.getItem("guestUniqueId") !== null
		? localStorage.getItem("guestUniqueId")
		: "";

// @ts-ignore
const utmCampaign: string =
	localStorage.getItem("utm_campaign") !== null
		? localStorage.getItem("utm_campaign")
		: "";

// @ts-ignore
const utmTerm: string =
	localStorage.getItem("utm_term") !== null
		? localStorage.getItem("utm_term")
		: "";

export const axiosInstance = axios.create({
	baseURL: "https://ecapi.saffronstays.com",
	headers: {
		"Access-Control-Allow-Origin": "*",
		guest_unique_identifier: uniqueId,
		UTM_SOURCE: utmSource,
		UTM_MEDIUM: utmMedium,
		UTM_CAMPAIGN: utmCampaign,
		UTM_TERM: utmTerm,
	},
});

// export const axiosInstanceDev = axios.create({
// 	baseURL: "http://3.6.92.65:3000",
// 	headers: {
// 		"Access-Control-Allow-Origin": "*",
// 		guest_unique_identifier: uniqueId,
// 		UTM_SOURCE: utmSource,
// 		UTM_MEDIUM: utmMedium,
// 		UTM_CAMPAIGN: utmCampaign,
// 		UTM_TERM: utmTerm,
// 	},
// });

export const axiosInstance2 = axios.create({
	baseURL: "https://ecapi2.saffronstays.com",
	headers: {
		"Access-Control-Allow-Origin": "*",
		guest_unique_identifier: uniqueId,
		UTM_SOURCE: utmSource,
		UTM_MEDIUM: utmMedium,
		UTM_CAMPAIGN: utmCampaign,
		UTM_TERM: utmTerm,
	},
});

export const goAxiosInstance = axios.create({
	baseURL: "https://go.saffronstays.com/api",
});
