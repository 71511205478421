export const featuredLinks = [
	{
		id: 1,
		title: "Maharashtra",
	},
	{
		id: 2,
		title: "Himachal",
	},
	{
		id: 3,
		title: "Uttarakhand",
	},
	{
		id: 4,
		title: "Goa",
	},
	{
		id: 5,
		title: "Nilgiris",
	},
	{
		id: 6,
		title: "Wayanad",
	},
	{
		id: 7,
		title: "Coorg",
	},
	{
		id: 8,
		title: "Rajasthan",
	},
];
