import { useCallback, useContext, useEffect, useRef, useState } from "react";

// components
import { FeaturedCardContainer } from "../FeaturedCardContainer";

// Icons
import { SvgButtonArrowLeft } from "assets/icons/js/SvgButtonArrowLeft";
import { SvgButtonArrowRight } from "assets/icons/js/SvgButtonArrowRight";

// data
import { featuredLinks } from "lib/featuredLinks";

// context
import { FeaturedHomesInterface } from "context/FeaturedHomesData";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";

// scss
import "./style.scss";
import { HomeScrollContainerHeader } from "components/NewHomePage/HomeScrollContainerHeader";
import useCheckEndsReached from "customHooks/useCheckEndsReached";

const FeaturedScrollMenu = ({ heading = "Featured Homes", filters = true }) => {
	const [active, setActive] = useState("Maharashtra");

	const { featuredHomes, setQuery, setLocation } = useContext(
		FeaturedHomesInterface,
	);

	const listRef = useRef(null);

	const { reachedRight, reachedLeft } = useCheckEndsReached({
		ref: listRef,
	});

	const width = useMediaQuery();

	const handleClick = useCallback(
		(title) => {
			setActive(title);
			if (title === "Maharashtra") {
				setLocation({
					latitude: "18.9809",
					longitude: "73.5008",
				});
			}
			if (title === "Goa") {
				setLocation({
					latitude: "15.2993",
					longitude: "74.1240",
				});
			}
			if (title === "Wayanad") {
				setLocation({
					latitude: "11.6854",
					longitude: "76.1320",
				});
			}
			if (title === "Himachal") {
				setLocation({
					latitude: "32.212",
					longitude: "77.198",
				});
			}
			if (title === "Rajasthan") {
				setLocation({
					latitude: "26.9124",
					longitude: "75.7873",
				});
			}
			if (title === "Nilgiris") {
				setLocation({
					latitude: "11.4916",
					longitude: "76.7337",
				});
			}
			if (title === "Coorg") {
				setLocation({
					latitude: "12.4244",
					longitude: "75.7382",
				});
			}
			if (title === "Uttarakhand") {
				setLocation({
					latitude: "30.0668",
					longitude: "79.0193",
				});
			}
			if (title === "Rajasthan") {
				setLocation({
					latitude: "27.0238",
					longitude: "74.2179",
				});
			}
		},
		[setLocation],
	);

	useEffect(() => {
		if (active) {
			setQuery(active);
		}
	}, [active, setQuery]);

	return (
		<section className="featured__scroll-menu">
			<div className="featured__scroll-menu-container">
				{/* Header  */}
				<HomeScrollContainerHeader
					slideLeft={() => (listRef.current.scrollLeft += -500)}
					// @ts-ignore
					slideRight={() => (listRef.current.scrollLeft += 500)}
					heading={heading}
					reachedLeft={reachedLeft}
					reachedRight={reachedRight}
				/>
				{/* middle links */}
				{filters && (
					<div className="featured__scroll-header-title">
						{featuredLinks?.map(({ title, id }) => (
							<span
								className={active === title ? "active" : ""}
								onClick={() => handleClick(title)}
								key={Math.random() * 100 * id}
							>
								<p className={active === title ? "active-tab" : ""}>{title}</p>
							</span>
						))}
					</div>
				)}
				{/* Cards Container */}
				{active && (
					<FeaturedCardContainer
						type={active}
						listRef={listRef}
						featuredHomes={featuredHomes}
					/>
				)}
			</div>
		</section>
	);
};

export default FeaturedScrollMenu;
